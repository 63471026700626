import axios from 'axios';

const helper = {
    sendSMS: async (sms_data) => {
        try {
            if (!sms_data || !sms_data.mobiles) {
                throw new Error('Invalid SMS data');
            }

            var mobile = sms_data.mobiles.toString().replace(/\+/g, '');
            mobile = mobile.length > 10 ? mobile : "91"+mobile;

            const data = {
                template_id: sms_data.template_id,
                short_url: sms_data.short_url,
                recipients: [{
                    mobiles: mobile,
                    var1: sms_data.var1,
                    var2: sms_data.var2
                }]
            };

            const response = await axios.post('https://appapi.parkingpal.app/send-sms', 
                data,
                {
                    headers: {
                        'accept': 'application/json',
                        'content-type': 'application/json'
                    }
                }
            );

            return response.data;
        } catch (error) {
            console.error('Error sending SMS:', error);
            throw error;
        }
    }
};

export default helper;